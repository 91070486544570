<template>
    <div>
        <modal class="new-modal-small" :name="modal_name" transition="nice-modal-fade" :delay="100" height="auto" width="500" :min-width="200" :max-width="500" :pivot-y="0.5" :adaptive="true" :scrollable="true">
            <div class="v-modal-content d-flex flex-column justify-content-center align-items-center text-center" style="background-color: #fff !important; height: 35em !important;">
                <img src="/static/images/delete_pop_icon.svg" style="height: 10rem !important" />
                <div class="mt-5">
                    <div class="mb-4" style="color:#f30d0d;font-size: 18px;">
                       <span v-if="group_type == 'investor'">Delete Investor</span> 
                       <span v-if="group_type == 'banker'">Delete Banker</span> 
                       <span v-if="group_type == 'supplier'">Delete Supplier</span> 
                       <span v-if="group_type == 'board'">Delete Board Member</span> 
                       <span v-if="group_type == 'shareholder'">Delete Share Holder</span> 
                    </div>
                    <div>
                        <span class="fs-15 fw-600" v-if="group_type == 'investor'">Are You Sure You Want To Delete this Investor?</span> 
                        <span class="fs-15 fw-600" v-if="group_type == 'banker'">Are You Sure You Want To Delete this Banker?</span> 
                        <span class="fs-15 fw-600" v-if="group_type == 'supplier'">Are You Sure You Want To Delete this Supplier?</span> 
                        <span class="fs-15 fw-600" v-if="group_type == 'board'">Are You Sure You Want To Delete this Board Member?</span> 
                        <span class="fs-15 fw-600" v-if="group_type == 'shareholder'">Are You Sure You Want To Delete this Share Holder?</span> 
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-center mt-6">
                    <button
                        type="button"
                        class="btn btn-new-primary-bordered"
                        style="box-shadow: none;min-width: 5rem;"
                        @click="hideDeleteInvestorsModal()"
                    >Cancel</button>
                    <button v-if="group_type == 'investor'"
                        type="button"
                        class="btn btn-new-primary ml-4"
                        style="box-shadow: none;min-width: 5rem;"
                        @click="deleteCompany"
                    >Delete Investor</button>
                    <button v-if="group_type == 'banker'"
                        type="button"
                        class="btn btn-new-primary ml-4"
                        style="box-shadow: none;min-width: 5rem;"
                        @click="deleteCompany"
                    >Delete Banker</button>
                    <button v-if="group_type == 'supplier'"
                        type="button"
                        class="btn btn-new-primary ml-4"
                        style="box-shadow: none;min-width: 5rem;"
                        @click="deleteCompany"
                    >Delete Supplier</button>
                    <button v-if="group_type == 'board'"
                        type="button"
                        class="btn btn-new-primary ml-4"
                        style="box-shadow: none;min-width: 5rem;"
                        @click="deleteCompany"
                    >Delete Board Member</button>
                    <button v-if="group_type == 'shareholder'"
                        type="button"
                        class="btn btn-new-primary ml-4"
                        style="box-shadow: none;min-width: 5rem;"
                        @click="deleteCompany"
                    >Delete Share Holder</button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>

export default ({
    props:['modal_name', 'group_type'],
    data(){
        return{

        }
    },
    methods:{
        hideDeleteInvestorsModal(){
            this.$modal.hide(this.modal_name);
            this.$emit('hideDeleteInvestorsModal');
        },
        deleteCompany() {
            this.$modal.hide(this.modal_name);
            this.$emit('deleteInvestorsSuccess');
        }
    }
})

</script>

<style scoped>
    .btn-primary-outline{
        color: #2b6ad0;
		border-radius: 5px;
		padding-left: 35px;
		padding-right: 35px;
		text-transform: none;
		font-weight: 500;
		line-height: 30px;
		box-shadow: none;
		border-color: 2px solid #2b6ad0;
		box-shadow: none !important;
		font-weight: 500;
    }
</style>
